import { useState } from 'react';

import { NavItemFragment } from 'graphql/generated';

import Image from 'components/Image';

import { HOME_URL } from 'lib/urls';
import hacksoftLogo from 'public/hacksoft.svg';

import NavigationLinks from './NavigationLinks';

import * as S from './styles';

const Navigation = (props: { navItems: NavItemFragment[] }) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <S.Navigation>
      <S.StyledRootContainer>
        <S.StyledLink href={HOME_URL}>
          <Image alt='HackSoft logo' src={hacksoftLogo} width={185} height={56} />
        </S.StyledLink>
        <S.NavigationLinksWrapper>
          <NavigationLinks {...props} />
        </S.NavigationLinksWrapper>

        <S.MenuIconButton onClick={() => setIsDrawerOpened((x) => !x)}>
          <S.MenuIcon />
        </S.MenuIconButton>
      </S.StyledRootContainer>
      <S.StyledDrawer isOpened={isDrawerOpened} onClose={() => setIsDrawerOpened(false)}>
        <NavigationLinks {...props} />
      </S.StyledDrawer>
    </S.Navigation>
  );
};

export default Navigation;
