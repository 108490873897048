import { HTMLAttributeAnchorTarget } from 'react';

import { LinkProps } from 'next/link';

import * as S from './styles';

interface ILink extends LinkProps {
  active?: string | boolean;
  className?: string;
  target?: HTMLAttributeAnchorTarget;
  children?: React.ReactNode;
  rel?: string;
  style?: any;
  id?: string;
}

const Link = ({ active, ...props }: ILink) => {
  return <S.StyledLink active={active !== undefined ? active.toString() : 'false'} {...props} />;
};

export default Link;
