import Link from 'next/link';

import styled from '@emotion/styled';

import Drawer from 'components/Drawer';
import RootContainer from 'components/RootContainer';

import burgerIcon from './assets/menu.svg';

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.white};

  ${({ theme }) => theme.breakpoints.lg} {
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

export const StyledRootContainer = styled(RootContainer)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

export const StyledLink = styled(Link)`
  padding-left: 10px;
`;

export const StyledDrawer = styled(Drawer)`
  overflow: auto;
  > ul {
    display: block;
  }
  > ul li {
    padding: 10px 0;
  }
  > ul a {
    text-transform: none;
  }
`;

export const NavigationLinksWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.lg} {
    display: none !important;
  }
`;

export const MenuIconButton = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.lg} {
    display: block;
    padding: 10px 16px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.transparentBlack};
    }
  }
`;

export const MenuIcon = styled.div`
  width: 28px;
  content: url(${burgerIcon});
`;
