import styled from '@emotion/styled';

interface IDrawerProps {
  isOpened: boolean;
}

interface IOverlayProps {
  isOpened: boolean;
}

export const Drawer = styled.div`
  position: fixed;
  transform: ${(props: IDrawerProps) =>
    props.isOpened ? 'translateX(0px)' : 'translateX(-340px)'};
  width: 215px;
  height: 100%;
  top: 0;
  background: ${({ theme }) => theme.colors.secondary.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding-top: 20px;
  z-index: 1000;
  transition: transform ease 0.2s;
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  display: ${(props: IOverlayProps) => (props.isOpened ? 'block' : 'none')};
  background: ${({ theme }) => theme.colors.secondary.transparentBlack};
`;
