import {
  faLinkedin,
  faSquareFacebook,
  faSquareGithub,
  faSquareInstagram,
  faSquareTwitter,
  faSquareYoutube,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { faSquareRss } from '@fortawesome/free-solid-svg-icons';

import Image from 'components/Image';

import { OPEN_POSITIONS_URL } from 'lib/constants';
import { BLOG_URL } from 'lib/urls';

import * as S from './styles';

interface IFooter {
  className?: string;
}

const Footer = ({ className }: IFooter) => (
  <S.Footer className={className}>
    <S.Content>
      <S.Left>
        <Image alt='HackSoft logo' src='/hacksoft_logo_white.png' width='247' height='43' />

        <div>Your development partner beyond code.</div>
      </S.Left>

      <S.Right>
        <S.Links>
          <S.StyledLink href='https://www.youtube.com/c/HackSoft' target='_blank' rel='noreferrer'>
            <S.StyledFontAwesomeIcon icon={faSquareYoutube} />
          </S.StyledLink>

          <S.StyledLink
            href='https://www.linkedin.com/company/hacksoft/'
            target='_blank'
            rel='noreferrer'
          >
            <S.StyledFontAwesomeIcon icon={faLinkedin} />
          </S.StyledLink>

          <S.StyledLink href='https://twitter.com/HackSoft_' target='_blank' rel='noreferrer'>
            <S.StyledFontAwesomeIcon icon={faSquareTwitter} />
          </S.StyledLink>

          <S.StyledLink
            href='https://www.facebook.com/HackSoftOfficial'
            target='_blank'
            rel='noreferrer'
          >
            <S.StyledFontAwesomeIcon icon={faSquareFacebook} />
          </S.StyledLink>

          <S.StyledLink
            href='https://www.instagram.com/wearehacksoft/'
            target='_blank'
            rel='noreferrer'
          >
            <S.StyledFontAwesomeIcon icon={faSquareInstagram} />
          </S.StyledLink>

          <S.StyledLink
            href='https://www.tiktok.com/@wearehacksoft'
            target='_blank'
            rel='noreferrer'
          >
            <S.StyledFontAwesomeIcon icon={faTiktok} />
          </S.StyledLink>

          <S.StyledLink href='https://github.com/HackSoftware' target='_blank' rel='noreferrer'>
            <S.StyledFontAwesomeIcon icon={faSquareGithub} />
          </S.StyledLink>

          <S.StyledLink href='/blog/rss/' target='_blank' rel='noreferrer'>
            <S.StyledFontAwesomeIcon icon={faSquareRss} />
          </S.StyledLink>
        </S.Links>

        <S.InternalLinks>
          <S.StyledLink href='https://www.hacksoft.io/contact-us' target='_blank' rel='noreferrer'>
            Contact us
          </S.StyledLink>
          <S.StyledLink href='https://www.hackcast.fm' target='_blank' rel='noreferrer'>
            HackCast
          </S.StyledLink>
          <S.StyledLink
            href='https://www.hacksoft.io/privacy-policy'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </S.StyledLink>
          <S.StyledLink href={OPEN_POSITIONS_URL} target='_blank' rel='noreferrer'>
            Careers
          </S.StyledLink>{' '}
          <S.StyledLink href={BLOG_URL}>Blog</S.StyledLink>
        </S.InternalLinks>
      </S.Right>
    </S.Content>
  </S.Footer>
);

export default Footer;
