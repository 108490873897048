import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from 'components/Link';

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.secondary.footerGray};
  padding: 50px 33px 50px 33px;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary.white};
  margin-top: 100px;
  flex-wrap: wrap;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.sm} {
    padding: 10px;
    flex-direction: column;
    width: auto;
  }
`;

export const Middle = styled.div`
  text-align: center;
`;

export const Links = styled.div`
  display: flex;
  gap: 5px;
  padding: 5px 0;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.sm} {
    justify-content: center;
  }
`;

export const InternalLinks = styled.div`
  padding: 10px 0;
  display: flex;
  gap: 3vw;
  flex-wrap: wrap;
  margin-top: 15px;
  ${({ theme }) => theme.breakpoints.sm} {
    justify-content: center;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 5px;
  font-size: 1.5rem;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary.white};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.sm} {
    align-items: center;
    & > div {
      text-align: center;
    }
  }
`;

export const Right = styled.div`
  ${({ theme }) => theme.breakpoints.md} {
    padding-top: 10px;
  }
`;

export const LinkContainer = styled.div`
  ${({ theme }) => theme.breakpoints.md} {
    display: inline;
    padding: 0 10px;
  }
`;
