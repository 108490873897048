import Link from 'next/link';

import styled from '@emotion/styled';

interface IStyledLinkProps {
  active: string;
}

export const StyledLink = styled(Link)<IStyledLinkProps>`
  background: ${({ active, theme }) =>
    active === 'true' ? theme.colors.secondary.lightGray : 'none'};
`;
