import { ReactNode } from 'react';

import * as S from './styles';

interface IRootContainer {
  children: ReactNode;
  className?: string;
}

const RootContainer = ({ children, className }: IRootContainer) => (
  <S.RootContainer className={className}>{children}</S.RootContainer>
);

export default RootContainer;
