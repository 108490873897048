import { ReactNode } from 'react';

import * as S from './styles';

interface IDrawer {
  children: ReactNode;
  isOpened: boolean;
  onClose: () => void;
  className?: string;
}

const Drawer = ({ children, isOpened, onClose, className }: IDrawer) => (
  <>
    <S.Drawer isOpened={isOpened} onClick={onClose} className={className}>
      {children}
    </S.Drawer>
    <S.Overlay isOpened={isOpened} onClick={onClose} />
  </>
);

export default Drawer;
