import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from 'components/Link';

interface IStyledAnchorProps {
  active: boolean;
}

export const MainList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const StyledListItem = styled.li`
  margin: 0 10px;
  ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 5px;
  }
`;

export const Dropdown = styled(StyledListItem)`
  z-index: 99;
  &:hover ul {
    display: block;
  }
`;

export const DropDownIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

export const DropdownList = styled.ul`
  display: none;
  margin-top: 12px;
  padding: 0;
  position: absolute;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background: #a8a8a8;
  ${({ theme }) => theme.breakpoints.lg} {
    box-shadow: none;
    background: none;
    display: block;
    position: static;
    padding-left: 10px;
    &:hover {
      position: static;
    }
  }
`;

export const DropdownStyledLink = styled(Link)`
  display: block;
  padding: 16px;
  color: ${({ theme }) => theme.colors.secondary.black};
  background: ${({ theme }) => theme.colors.secondary.white};
  text-transform: uppercase;
  font-weight: 800;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, background-color;
  ${({ theme }) => theme.breakpoints.lg} {
    color: ${({ theme }) => theme.colors.secondary.black};

    &:hover {
      color: ${({ theme }) => theme.colors.primary.darkOrange};
    }

    &:active,
    &[active='true'] {
      background: ${({ theme }) => theme.colors.secondary.lightGray};
    }
  }

  &:hover {
    background: #d9d9d98c;
    color: ${({ theme }) => theme.colors.primary.orange};
  }

  &:active,
  &[active='true'] {
    color: ${({ theme }) => theme.colors.primary.orange};
  }
`;

export const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary.black};
  padding: 0.7rem 0.4rem;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, background-color;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary.lightGray};
  }

  &:active,
  &[active='true'] {
    color: ${({ theme }) => theme.colors.primary.orange};
  }

  ${({ theme }) => theme.breakpoints.lg} {
    display: block;
    margin-top: -1rem;
  }
`;

export const StyledAnchor = styled.a<IStyledAnchorProps>`
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary.black};
  padding: 0.7rem 0.4rem;
  position: relative;
  border-radius: 6px;
  transition-duration: 0.2s;
  transition-property: color, background-color;
  background: ${({ active, theme }) => (active ? theme.colors.secondary.lightGray : 'none')};

  &:hover {
    background: ${({ theme }) => theme.colors.secondary.lightGray};
  }

  &:active,
  &[active='true'] {
    color: ${({ theme }) => theme.colors.primary.orange};
  }
`;
