import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavItemFragment } from 'graphql/generated';
import _ from 'lodash';

import * as S from './styles';

const NavigationLinks = ({ navItems }: { navItems: Array<NavItemFragment> }) => {
  const router = useRouter();

  const isActive = useCallback(
    ({
      href,
      pathname,
      queryParams
    }: {
      href: string;
      pathname: string;
      queryParams?: string | string[];
    }) => {
      let queryParam = queryParams;

      if (Array.isArray(queryParams)) {
        queryParam = queryParams[0];
      }

      if (pathname.startsWith(href)) {
        // This is for pages we define on app-level.
        return true;
      }

      if (href === `/${queryParam}`) {
        // This is for parent pages we define in Dato.
        return true;
      }

      if (
        _.isEqual(
          href.split('/').filter((item) => item !== ''),
          queryParams
        )
      ) {
        // This is for child pages we define in Dato.
        return true;
      }

      if (href === '/blog' && pathname.startsWith('/category')) {
        // The blog categories page is the same as the blog page but filtered.
        return true;
      }

      return false;
    },
    []
  );

  const buildHref = useCallback(({ navItem }: { navItem: NavItemFragment }): string => {
    if (!_.isEmpty(navItem.externalUrl)) {
      return navItem.externalUrl as string;
    } else if (!_.isEmpty(navItem.internalLink)) {
      return navItem.internalLink as string;
    } else if (!_.isNil(navItem.contentPageLink)) {
      return `/${navItem.contentPageLink.slug}`;
    }

    return '#';
  }, []);

  return (
    <S.MainList>
      {navItems.map((item: any) => {
        const isNested = item.children.length > 0;
        const itemHref = buildHref({ navItem: item });
        const isItemActive = isActive({
          queryParams: router.query.slug,
          pathname: router.pathname,
          href: buildHref({ navItem: item })
        });

        return isNested ? (
          <S.Dropdown>
            <S.StyledLink href={itemHref} active={isItemActive}>
              {item.title}
              {isNested && <S.DropDownIcon icon={faChevronDown} />}
            </S.StyledLink>

            <S.DropdownList>
              {item.children.map((child: any) => (
                <S.DropdownStyledLink
                  key={child.title}
                  href={buildHref({ navItem: child })}
                  active={isActive({
                    queryParams: router.query.slug,
                    pathname: router.pathname,
                    href: buildHref({ navItem: child })
                  })}
                >
                  {child.title}
                </S.DropdownStyledLink>
              ))}
            </S.DropdownList>
          </S.Dropdown>
        ) : (
          <S.StyledListItem key={item.internalLink}>
            <S.StyledLink href={itemHref} active={isItemActive}>
              {item.title}
            </S.StyledLink>
          </S.StyledListItem>
        );
      })}
    </S.MainList>
  );
};

export default NavigationLinks;
