export const HOME_URL = '/';
export const APPROACH_URL = '/approach';
export const PORTFOLIO_URL = '/portfolio';
export const TEAM_URL = '/team';
export const COMPANY_URL = '/company';
export const BLOG_URL = '/blog';
export const OPEN_SOURCE_URL = '/open-source';

export const SOLUTIONS_URL = '/solutions';
export const PROPTECH_URL = '/solutions/proptech';
export const PYTHON_DJANGO_URL = '/solutions/django';
export const JS_REACT_URL = '/solutions/react';
export const NEXT_URL = '/solutions/nextjs';
export const CUSTOM_SOFTWARE_DEVELOPMENT_URL = '/solutions/custom-software-development';
export const BLOGPOST_URL = ({ slug }: { slug: string }) => `${BLOG_URL}/${slug}`;
export const TAG_URL = ({ slug }: { slug: string }) => `/category/${slug}`;

export const CASE_STUDIES_URL = '/case-studies';
export const CASE_STUDY_URL = ({ slug }: { slug: string }) => `${CASE_STUDIES_URL}/${slug}`;
